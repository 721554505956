import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';

export type DialogReturnType<T> = T extends BaseDialogContentComponent<
    infer U,
    unknown
>
    ? U
    : T extends BaseDialogContentComponent<infer U, void>
    ? U
    : never;
export type DialogConfigType<T> = T extends BaseDialogContentComponent<
    unknown,
    infer U
>
    ? U
    : T extends BaseDialogContentComponent<void, infer U>
    ? U
    : never;

@Component({
    template: ''
})
export class BaseDialogContentComponent<
    ReturnType = unknown,
    ConfigType = unknown
> implements AfterViewInit {
    close$: Subject<ReturnType> = new Subject();
    readonly title: string;
    public config?: ConfigType;
    public get safeConfig(): Partial<ConfigType> {
        return this.config || {};
    }

    @Output() viewInit = new EventEmitter();

    protected destroyFunction: () => void;

    ngAfterViewInit() {
        this.viewInit.emit();
    }

    init(destroyFunction: () => void, config: ConfigType) {
        this.config = config;
        this.destroyFunction = destroyFunction;
        this.afterInit();
    }

    protected afterInit() {}

    close(returnValue?: ReturnType) {
        setTimeout(() => {
            this.close$.next(returnValue);
            this.close$.complete();
        }, 0);
    }

    escapePressed(_e: KeyboardEvent) {
        this.close();
    }
    clickOutside(_e: MouseEvent) {
        this.close();
    }
}
