<div class="pex-dialog-close">
    <button
        class="circle-close-button"
        type="button"
        (click)="close()"
        *ngIf="!this.config?.isCloseDisabled"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon" aria-hidden="true">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>

<div class="pex-dialog-content-inner">
    <div
        class="pex-dialog-content-title"
        *ngIf="!!this.config?.title"
        [innerHTML]="this.config?.title"
    ></div>
    <div
        class="pex-dialog-content-message"
        *ngIf="!!this.config?.body"
        [innerHTML]="this.config?.body | sanitizeHtml"
    ></div>
</div>
