<div [pexDraggable]="true" position="fixed" class="pex-dialog">
    <div
        class="pex-dialog-content"
        [style.z-index]="priority + 1000"
        (click)="$event.stopPropagation()"
        role="dialog"
        aria-modal="true"
        [attr.aria-label]="contentComponent?.title ?? (contentComponent?.config?.title | translate) ?? null"
    >
        <ng-template #content></ng-template>
    </div>
</div>
