import { Action, createReducer, on } from '@ngrx/store';
import { CONFERENCE_ACTIONS } from '../conference/conference.actions';
import { isBatch } from '../shared/batch-ngrx';
import { PARTICIPANT_ACTIONS } from './participant.actions';
import * as participant from './participant.reducer';
import { reducer as participantReducer } from './participant.reducer';

export interface State {
    roster: string[];
    participants: { [uuid: string]: participant.State };
}

export const initialState: State = {
    roster: [],
    participants: {}
};

export function reducer(state: State, action: Action) {
    return participantsReducer(state, action);
}

const participantsReducer = createReducer(
    initialState,
    // Clear the roster list when connecting to a new conference
    on(CONFERENCE_ACTIONS.connectSuccess, () =>
        Object.assign({}, initialState)
    ),

    // Clear the roster list when tranfered to a new conference
    on(CONFERENCE_ACTIONS.transferSuccess, () =>
        Object.assign({}, initialState)
    ),

    on(
        PARTICIPANT_ACTIONS.connectingAction,
        PARTICIPANT_ACTIONS.connectSuccessAction,
        (state, action) => {
            let useNewObjects = true;

            if (isBatch(action) && action.isBatch) {
                useNewObjects = action.useNewObjects;
            }

            let newState = state;
            if (useNewObjects) {
                newState = { ...state };
                newState.participants = { ...state.participants };
            }

            const newParticipant = action.payload;

            if (state.roster.indexOf(newParticipant.uuid) > -1) {
                const oldParticipant = state.participants[newParticipant.uuid];
                if (oldParticipant.serviceType === 'connecting') {
                    newState.participants[newParticipant.uuid] = newParticipant;
                    return newState;
                } else {
                    return state;
                }
            }

            if (useNewObjects) {
                newState.roster = [...state.roster];
            }

            newState.participants[newParticipant.uuid] = newParticipant;
            newState.roster.push(newParticipant.uuid);

            return newState;
        }
    ),

    on(
        PARTICIPANT_ACTIONS.connectingFailedAction,
        PARTICIPANT_ACTIONS.disconnectSuccessAction,
        (state, action) => {
            let useNewObjects = true;

            if (isBatch(action) && action.isBatch) {
                useNewObjects = action.useNewObjects;
            }

            const uuidIndex = state.roster.indexOf(action.payload);
            if (uuidIndex === -1) {
                return state;
            }

            let newState = state;

            if (useNewObjects) {
                newState = { ...state };
                newState.roster = [...state.roster];
            }

            newState.roster.splice(uuidIndex, 1);
            return newState;
        }
    ),

    on(
        PARTICIPANT_ACTIONS.updateAction,
        PARTICIPANT_ACTIONS.mutedChangedAction,
        PARTICIPANT_ACTIONS.videoMutedChangedAction,
        PARTICIPANT_ACTIONS.mainVideoDroppedChangedAction,
        PARTICIPANT_ACTIONS.protocolChangedAction,
        PARTICIPANT_ACTIONS.presentingChangedAction,
        PARTICIPANT_ACTIONS.roleChangedAction,
        PARTICIPANT_ACTIONS.rxPresChangedAction,
        PARTICIPANT_ACTIONS.fECCSupportedChanged,
        PARTICIPANT_ACTIONS.callTypeChangedAction,
        PARTICIPANT_ACTIONS.serviceTypeChangedAction,
        PARTICIPANT_ACTIONS.spotlightChangedAction,
        PARTICIPANT_ACTIONS.buzzChangedAction,
        PARTICIPANT_ACTIONS.videoSilentChangedAction,
        PARTICIPANT_ACTIONS.streamingChangedAction,
        PARTICIPANT_ACTIONS.needsPresentationInMixChangedAction,
        PARTICIPANT_ACTIONS.roomIdChangedAction,
        PARTICIPANT_ACTIONS.clientMutedChangedAction,
        (state, action) => {
            const participant = state.participants[action.payload.uuid];
            if (participant) {
                const newState = Object.assign({}, state, {
                    participants: Object.assign({}, state.participants, {
                        [action.payload.uuid]: participantReducer(
                            participant,
                            action
                        )
                    })
                });
                return newState;
            } else {
                return state;
            }
        }
    ),

    on(PARTICIPANT_ACTIONS.canControlChangedAction, (state, action) => {
        const participant = state.participants[action.payload.uuid];
        if (participant) {
            const newState = Object.assign({}, state, {
                participants: Object.assign({}, state.participants, {
                    [action.payload.uuid]: participantReducer(
                        participant,
                        action
                    )
                })
            });
            return newState;
        }
        return state;
    }),

    on(PARTICIPANT_ACTIONS.stageUpdateAction, (state, action) => {
        const newState = Object.assign({}, state, {
            participants: Object.assign({}, state.participants)
        });

        for (const update of action.payload) {
            const participant = newState.participants[update.participant_uuid];
            if (participant) {
                participant.vad = update.vad;
                participant.stageIndex = update.stage_index;
            }
        }
        return newState;
    })
);
