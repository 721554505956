<div
    *ngIf="devicesSetup !== 'bandwidth'"
    class="pex-md-settings-wrapper"
    role="menu"
>
    <div
        [id]="
            device.deviceId === (setup.device$ | async)?.deviceId
                ? 'settings-label-active'
                : ''
        "
        class="pex-md-settings__item"
        [class.pex-md-settings__item--conference]="inConference"
        (keyup.enter)="deviceItem.click()"
        (keyup.space)="deviceItem.click()"
        role="menuitem"
        tabindex="0"
        [class.active]="device.deviceId === (setup.device$ | async)?.deviceId"
        *ngFor="let device of devices; let i = index"
        (click)="setDevice(setup.setMethod, device, $event)"
        [title]="device.label || setup.kind | translate: { n: i + 1 }"
        #deviceItem
    >
        <span>{{ device.label || setup.kind | translate: { n: i + 1 } }}</span>
    </div>
    <ng-container *ngIf="!inConference">
        <div
            class="pex-md-settings__info"
            *ngIf="
                mediaDeviceService.currentDeviceList ===
                    'SETTINGS_DIALOG.CHANGE_CAMERA' &&
                platformService.platform !== 'ios'
            "
        >
            {{ 'SETTINGS_DIALOG.NO_CAMERA' | translate }}
        </div>
        <div
            class="pex-md-settings__info"
            *ngIf="
                mediaDeviceService.currentDeviceList ===
                    'SETTINGS_DIALOG.CHANGE_MICROPHONE' &&
                platformService.platform !== 'ios'
            "
        >
            {{ 'SETTINGS_DIALOG.NO_MIC' | translate }}
        </div>
    </ng-container>
</div>
<div
    *ngIf="devicesSetup === 'bandwidth'"
    class="pex-md-settings-wrapper"
    role="menu"
>
    <div
        [id]="
            bandwidth === mediaDeviceService.bandwidth
                ? 'settings-label-active'
                : ''
        "
        class="pex-md-settings__item"
        [class.pex-md-settings__item--conference]="inConference"
        (keyup.enter)="bandwidthItem.click()"
        (keyup.space)="bandwidthItem.click()"
        role="menuitem"
        tabindex="0"
        [class.active]="bandwidth === mediaDeviceService.bandwidth"
        *ngFor="let bandwidth of bandwidths; let i = index"
        (click)="setBandwidth(bandwidth); navigateAway($event)"
        #bandwidthItem
    >
        <span>{{
            mediaDeviceService.bandwidthLabelsOptions[bandwidth]
                ? (mediaDeviceService.bandwidthLabelsOptions[bandwidth]
                  | translate: { value: bandwidth })
                : ('SETTINGS_DIALOG.BANDWIDTH_WITH_NUMBER'
                  | translate: { value: bandwidth })
        }}</span>
    </div>
</div>
