import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';
import { ActionsDialogComponent } from './actions-dialog.component';
import { BaseDialogContainerComponent } from './base-dialog-container.component';
import { BaseDialogContentComponent } from './base-dialog-content.component';
import { CardDialogContainerComponent } from './card-dialog/card-dialog-container.component';
import { DialogContainerComponent } from './dialog-container.component';
import { FlexDialogContainerComponent } from './flex-dialog/flex-dialog-container.component';
import { FloatingDialogContainerComponent } from './floating-dialog-container.component';
import { FullscreenDialogContainerComponent } from './fullscreen-dialog/fullscreen-dialog-container.component';
import { TemplateDialogComponent } from './template-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    imports: [CommonModule, SharedModule, A11yModule],
    declarations: [
        BaseDialogContainerComponent,
        BaseDialogContentComponent,
        DialogContainerComponent,
        ActionsDialogComponent,
        FloatingDialogContainerComponent,
        TemplateDialogComponent,
        FullscreenDialogContainerComponent,
        CardDialogContainerComponent,
        FlexDialogContainerComponent
    ]
})
export class DialogModule {}
