<div role="menu">
    <ng-container *ngFor="let item of deviceList">
        <div
            *ngIf="(item.devices$ | async)?.length > 0"
            class="media-device-select"
            [class.media-device-select--conference]="inConference"
            [attr.aria-label]="
                (item.aria | translate) +
                ' ' +
                ((item.device$ | async)?.label || 'DEFAULT' | translate)
            "
            (keyup.enter)="deviceElement.click()"
            (keyup.space)="deviceElement.click()"
            role="menuitem"
            tabindex="0"
            (click)="
                deviceElement.lastElementChild.className !== item.class
                    ? navigateToDeviceSetup(item.kind, $event)
                    : null
            "
            #deviceElement
        >
            <svg
                id="settings-{{ item.icon }}-icon"
                class="media-device-select__icon settings-icons"
                aria-hidden="true"
            >
                <use attr.xlink:href="icons.svg#{{ item.icon }}" />
            </svg>
            <span>{{
                (item.device$ | async)?.label || 'DEFAULT' | translate
            }}</span>
        </div>
    </ng-container>

    <div
        class="media-device-select"
        [class.media-device-select--conference]="inConference"
        (keyup.enter)="bandwidthBtn.click()"
        (keyup.space)="bandwidthBtn.click()"
        role="menuitem"
        tabindex="0"
        (click)="navigateToDeviceSetup('bandwidth', $event)"
        *ngIf="showBandwidth"
        #bandwidthBtn
    >
        <svg
            id="settings-bandwidth-icon"
            class="media-device-select__icon"
            aria-hidden="true"
        >
            <use xlink:href="icons.svg#bandwidth" class="settings-icons" />
        </svg>
        <span>{{
            bandwidthLabelsSelected[mediaDeviceService.bandwidth]
                ? (bandwidthLabelsSelected[mediaDeviceService.bandwidth]
                  | translate)
                : ('SETTINGS_DIALOG.BANDWIDTH'
                  | translate: { value: mediaDeviceService.bandwidth })
        }}</span>
    </div>
</div>
