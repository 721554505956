import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { CONFERENCE_ACTIONS } from '../../conference/conference.actions';
import { HomeFacade } from '../../home/home.facade';
import { SettingsStateEnum } from '../../home/setting-overlay/settings-state.type';
import { MediaDeviceService } from '../../media-device/media-device.service';
import { DevicesSetupState } from '../../media-device/media-devices/devices-setup-state.type';
import { PlatformService } from '../../platform.service';
import { StorageService } from '../../services';
import {
    TakeUntilDestroy,
    untilDestroyed
} from '../../shared/take-until-destory.decorator';
import { State } from './../../app.reducer';
import { ConferenceFacade } from './../../conference/conference.facade';
import { DeviceList } from './../../media-device/media-devices/device-list.interface';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@TakeUntilDestroy()
@Component({
    selector: 'pex-media-devices-list',
    templateUrl: './media-devices-list.component.html',
    styleUrls: ['./media-devices-list.component.scss']
})
export class MediaDevicesListComponent implements OnInit, OnDestroy {
    @Input() inConference = false;

    audioOutputDevices$: Observable<MediaDeviceInfo[]>;
    headline: string;

    deviceList: { [x: string]: DeviceList };
    setup: DeviceList;

    subscription: Subscription;
    subscriptionConference: Subscription;

    devicesSetup: DevicesSetupState;

    devices: MediaDeviceInfo[];

    bandwidths: string[];
    bandwidthLabelsSelected: { [value: string]: string };
    bandwidthLabelsOptions: { [value: string]: string };

    constructor(
        private store$: Store<State>,
        public mediaDeviceService: MediaDeviceService,
        private storageService: StorageService,
        private changeDetectorRef: ChangeDetectorRef,
        private homeFacade: HomeFacade,
        private conferenceFacade: ConferenceFacade,
        public platformService: PlatformService,
        private liveAnnouncer: LiveAnnouncer
    ) {
        this.bandwidths = mediaDeviceService.bandwidths;
        this.bandwidthLabelsSelected =
            mediaDeviceService.bandwidthLabelsSelected;
        this.bandwidthLabelsOptions = mediaDeviceService.bandwidthLabelsOptions;

        this.audioOutputDevices$ = mediaDeviceService.audioOutputDevices$;

        this.deviceList = mediaDeviceService.deviceList;

        // make asynchronous update
        // for skip `ExpressionChangedAfterItHasBeenCheckedError` error
        setTimeout(() => {
            this.mediaDeviceService.enumerateMediaDevices();
        });
    }

    ngOnInit() {
        this.storageService.reset$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.changeDetectorRef.detectChanges());
        if (this.inConference) {
            this.subscription = this.conferenceFacade.deviceSetupState$.subscribe(
                (devicesSetup: DevicesSetupState) => {
                    this.devicesSetup = devicesSetup;
                    if (this.devicesSetup) {
                        this.setup = this.deviceList[devicesSetup];
                        if (this.setup && this.setup.devices$) {
                            this.setup.devices$.subscribe(devices => {
                                this.devices = devices.filter(
                                    device => device.deviceId !== 'none'
                                );
                            });
                        }
                    }
                }
            );
        } else {
            this.subscription = this.homeFacade
                .getDevicesSetup()
                .subscribe((devicesSetup: DevicesSetupState) => {
                    this.devicesSetup = devicesSetup;

                    if (this.devicesSetup) {
                        this.setup = this.deviceList[devicesSetup];
                        if (this.setup && this.setup.devices$) {
                            this.setup.devices$.subscribe(
                                devices => (this.devices = devices)
                            );
                        }
                    }
                });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    setDevice(
        setMethod: (d: MediaDeviceInfo, mutedByUser?: boolean) => void,
        device: MediaDeviceInfo,
        e: MouseEvent
    ) {
        setMethod(device, device.deviceId === 'none');
        this.liveAnnouncer.announce(`${device.label} selected`);
        this.navigateAway(e);
    }

    setBandwidth(bandwidth: string) {
        this.mediaDeviceService.setBandwidth(bandwidth);
    }

    navigateAway(event: Event) {
        if (this.inConference) {
            this.store$.dispatch(
                CONFERENCE_ACTIONS.setSidebarAction('SETTINGS_DEVICE_SETUP')
            );
        } else {
            this.homeFacade.setSettingsNavigate(SettingsStateEnum.MAIN);
        }
        event.stopPropagation();
    }
}
