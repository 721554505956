<div class="pex-dialog-close">
    <button
        type="button"
        class="circle-close-button"
        (click)="this.close()"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon" aria-hidden="true">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>

<div
    class="pex-dialog-content-inner"
    role="dialog"
    aria-labelledby="pex-registration-dialog-title"
    aria-modal="true"
>
    <div id="pex-registration-dialog-title" class="pex-dialog-content-title">
        {{ 'REGISTRATION_PROVISION_DIALOG.TITLE' | translate }}
    </div>
    <div class="pex-dialog-content-message">
        {{ 'REGISTRATION_PROVISION_DIALOG.MESSAGE' | translate }}
    </div>

    <div class="pex-registration">
        <div *ngIf="safeConfig.name" class="pex-registration__group">
            <input disabled class="pex-input" [value]="safeConfig.name" />
            <span class="pex-input-bar"></span>
            <label class="pex-input-label">{{
                'REGISTRATION_PROVISION_DIALOG.DISPLAY_NAME' | translate
            }}</label>
        </div>
        <div class="pex-registration__group">
            <input
                disabled
                class="pex-input"
                [value]="safeConfig.registrationHost"
            />
            <span class="pex-input-bar"></span>
            <label class="pex-input-label">{{
                'REGISTRATION.HOST' | translate
            }}</label>
        </div>
        <div class="pex-registration__group">
            <input
                disabled
                class="pex-input"
                [value]="safeConfig.registrationAlias"
            />
            <span class="pex-input-bar"></span>
            <label class="pex-input-label">{{
                'REGISTRATION.ALIAS' | translate
            }}</label>
        </div>
        <div
            *ngIf="provisionType === 'normal'; else adfsBlock"
            class="pex-registration__group"
        >
            <input
                disabled
                class="pex-input"
                [value]="safeConfig.registrationUsername"
            />
            <span class="pex-input-bar"></span>
            <label class="pex-input-label">{{
                'REGISTRATION.USERNAME' | translate
            }}</label>
        </div>
        <ng-template #adfsBlock>
            <div *ngIf="provisionType === 'adfs'">
                <div class="pex-registration__group">
                    <input
                        disabled
                        class="pex-input"
                        [value]="safeConfig.adfsFederationServiceName"
                    />
                    <span class="pex-input-bar"></span>
                    <label class="pex-input-label">{{
                        'REGISTRATION.ADFS_SERVICE_NAME' | translate
                    }}</label>
                </div>
                <div class="pex-registration__group">
                    <input
                        disabled
                        class="pex-input"
                        [value]="safeConfig.adfsResource"
                    />
                    <span class="pex-input-bar"></span>
                    <label class="pex-input-label">{{
                        'REGISTRATION.ADFS_RESOURCE_URI' | translate
                    }}</label>
                </div>
                <div class="pex-registration__group">
                    <input
                        disabled
                        class="pex-input"
                        [value]="safeConfig.adfsRedirectURI"
                    />
                    <span class="pex-input-bar"></span>
                    <label class="pex-input-label">{{
                        'REGISTRATION.ADFS_REDIRECT_URI' | translate
                    }}</label>
                </div>
            </div>
        </ng-template>
        <div *ngIf="safeConfig.brandingURL" class="pex-registration__group">
            <input
                disabled
                class="pex-input"
                [value]="safeConfig.brandingURL"
            />
            <span class="pex-input-bar"></span>
            <label class="pex-input-label">{{
                'REGISTRATION_PROVISION_DIALOG.BRANDING_URL' | translate
            }}</label>
        </div>
    </div>

    <div class="button-container">
        <div class="pex-dialog-content-button">
            <button
                type="button"
                class="dialog-button buttons green-action-button"
                (click)="close(true)"
            >
                {{ 'REGISTRATION_PROVISION_DIALOG.APPLY' | translate }}
            </button>
        </div>
        <div class="pex-dialog-content-button">
            <button
                type="button"
                class="dialog-button buttons red-action-button"
                (click)="close(false)"
            >
                {{ 'REGISTRATION_PROVISION_DIALOG.CANCEL' | translate }}
            </button>
        </div>
    </div>
</div>
