<div class="pex-dialog-close">
    <button
        type="button"
        class="circle-close-button"
        (click)="this.close()"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon" aria-hidden="true">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>

<form
    (ngSubmit)="addParticipant()"
    #addParticipantForm="ngForm"
    class="pex-dialog-content-inner"
    autocomplete="off"
>
    <h3 class="pex-dialog-content-title">
        {{ 'ADD_PARTICIPANT_DIALOG.TITLE' | translate }}
    </h3>

    <div class="pex-dialog-content-message">
        <div class="error-text" *ngIf="this.config?.previousURI">
            {{ 'ADD_PARTICIPANT_DIALOG.FAILED' | translate }}
        </div>
        <div class="participant-wrapper">
            <div class="participant-input">
                <input
                    id="dialog-add-participant-input"
                    type="text"
                    class="pex-text-input participant-text inputs"
                    [attr.aria-label]="
                        'ADD_PARTICIPANT_DIALOG.PLACEHOLDER' | translate
                    "
                    [placeholder]="
                        'ADD_PARTICIPANT_DIALOG.PLACEHOLDER' | translate
                    "
                    name="uri"
                    [class.grey]="this.config?.previousURI"
                    [formControl]="participantFormControl"
                    #participantURI
                    required=""
                />
                <!-- <div
                    class="participant-autocomplete"
                    *ngIf="directoryResults.length > 0"
                    #participantAutocomplete
                >
                    <div
                        (keyup.enter)="divEntryBtn.click()"
                        (keyup.space)="divEntryBtn.click()"
                        role="button"
                        tabindex="0"
                        class="participant-autocomplete-item"
                        [attr.aria-label]="entry.alias"
                        (click)="
                            participantURI.value = entry.alias;
                            directoryResults = []
                        "
                        *ngFor="let entry of directoryResults"
                        #divEntryBtn
                    >
                        <p class="participant-autocomplete-item__line">
                            {{ entry.alias }}
                        </p>
                        <p class="participant-autocomplete-item__line">
                            {{ entry.description }}
                        </p>
                    </div>
                </div> -->
            </div>
            <button
                class="participant-role"
                (click)="
                    $event.preventDefault(); contextMenu.open(participantRole)
                "
                #participantRole
            >
                <span *ngIf="!isHost">{{ 'GUEST' | translate }}</span>
                <span *ngIf="isHost">{{ 'HOST' | translate }}</span>

                <svg class="participant-role__icon" aria-hidden="true">
                    <use xlink:href="icons.svg#chevron-down" />
                </svg>
            </button>
            <ng-template
                pexContextMenu
                #contextMenu="pexContextMenu"
                role="listbox"
            >
                <pex-context-menu-item
                    (keyup.enter)="isHost = false; contextMenu.close()"
                    (keyup.space)="isHost = false; contextMenu.close()"
                    (click)="isHost = false"
                    role="button"
                    tabindex="0"
                    [icon]="
                        'icons.svg' + (!isHost ? '#check-square' : '#square')
                    "
                    [attr.aria-label]="'GUEST' | translate"
                    [label]="'GUEST' | translate"
                    #guestContextMenuBtn
                    role="option"
                ></pex-context-menu-item>
                <pex-context-menu-item
                    (keyup.enter)="isHost = true; contextMenu.close()"
                    (keyup.space)="isHost = true; contextMenu.close()"
                    (click)="isHost = true"
                    role="button"
                    tabindex="0"
                    [icon]="
                        'icons.svg' + (isHost ? '#check-square' : '#square')
                    "
                    [attr.aria-label]="'HOST' | translate"
                    [label]="'HOST' | translate"
                    #hostContextMenuBtn
                    role="option"
                ></pex-context-menu-item>
            </ng-template>
        </div>
    </div>
    <div class="pex-dialog-content-button">
        <button
            type="submit"
            class="dialog-button buttons green-action-button"
            id="conference-add-participant-btn"
            [class.gray-action-button]="!participantFormControl.valid"
            [disabled]="!participantFormControl.valid"
        >
            {{ 'ADD_PARTICIPANT_DIALOG.BUTTON' | translate }}
        </button>
    </div>
</form>
